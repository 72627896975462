import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LocalForm } from 'commons/js/components/form'
import { Flex, Box, Copy } from '@elparking/components'
import { FormattedMessage } from 'react-intl'

import ContinueMessage from './ContinueMessage'
import defaultCountry from 'commons/js/country'
import Routes from 'commons/js/routes/routes'
import LoaderButton from 'commons/js/ui/LoaderButton'
import { TRADENAME, getConstant } from 'commons/js/constants'
import EmailField from 'commons/js/ui/EmailField'
import TermsField from 'commons/js/ui/TermsField'
import FieldGroup from 'commons/js/ui/FieldGroup'
import getTerms from 'commons/js/api/userService/getTerms'
import * as api from 'commons/js/api'
import { useApi } from '@elparking/utils'

const IntentForm = ({
    userServiceRegisterIntent,
    getTerms,
    loading,
    disabled,
    setEmail,
    email,
    isUserServiceRegisterEnabled,
}) => {
    const [ termVersion, setTermVersion ] = useState(null)
    const {
        response: { result: terms, error: termsError },
        loading: loadingTerms,
        sendRequest: fetchTerms,
    } = useApi(getTerms)

    const {
        response: { result: intent, error: intentError },
        loading: loadingIntent,
        sendRequest: fetchIntent,
    } = useApi(userServiceRegisterIntent)

    useEffect(() => {
        fetchTerms()
    }, [fetchTerms, isUserServiceRegisterEnabled])

    useEffect(() => {
        if (terms && terms.version) {
            setTermVersion(terms.version)
        }
    }, [terms])

    const loadingForm = loadingTerms || termsError || loading || loadingIntent

    if (intent && !loadingIntent) {
        return <ContinueMessage onClick={() => fetchIntent({
            email,
            termsAndConditionsDocumentVersion: termVersion,
        })} email={email} />
    }

    return <LocalForm
      model='registrationIntent'
      onSubmit={({ email }) => {
        setEmail(email)
        fetchIntent({
            email,
            termsAndConditionsDocumentVersion: termVersion,
        })
      }}
      initialState={{
        email: '',
        acceptConditions: false,
      }}
    >
        <Flex
          flexWrap='wrap'
          justifyContent='space-between'
          flexDirection='column'>
            <Box w='100%'>
                <Box mb='16px'>
                    <Copy padding='0' size='xxLarge' fontWeight='medium'>
                        <FormattedMessage
                          defaultMessage='Introduce tu correo'
                        />
                    </Copy>
                    <Copy padding='0.5em 0 1em 0' size='medium' fontWeight='regular'>
                        <FormattedMessage
                          defaultMessage='Introduce el correo que quieres utilizar para que sea tu usuario en {tradename}'
                          values={{ tradename: <strong>{TRADENAME}</strong> }}
                        />
                    </Copy>
                </Box>
                {(intentError || termsError) && <Box background='error_light' p='0.5em' mb='1em'>
                    <Copy padding='0' size='small' fontWeight='medium'>
                        {intentError && <FormattedMessage defaultMessage='No se ha podido solicitar el alta.' />}
                        {termsError && <FormattedMessage defaultMessage='Ha sucedido un error.' />}
                    </Copy>
                    <Copy padding='0.5em 0 0 0' size='small' fontWeight='regular'>
                        <FormattedMessage defaultMessage='Inténtelo de nuevo más tarde' />
                    </Copy>
                </Box>}
                <FieldGroup disabled={loadingForm}>
                    <Box w='100%' flex='1 0 100%' mb='1.2em'>
                        <EmailField dataTest='register-email-field' />
                    </Box>
                </FieldGroup>
            </Box>
            <Box w='100%'>
                <FieldGroup disabled={loadingForm}>
                    <Box w='100%' flex='1 0 100%' mt='1em' mb='1.5em'>
                        <TermsField
                          id='terms'
                          dataTest='register-terms-field'
                          disabled={!termVersion}
                          renderTermsLink={() =>
                              <Copy key='terms' textDecoration='underline' fontWeight='medium' fontColor='inherit' fontSize='inherit' font as='a' rel='noopener noreferrer' href={Routes.termsAndConditionsPath(null, getConstant('LANGUAGE'), defaultCountry)} target='_blank'>
                                  <FormattedMessage
                                    defaultMessage='los Términos y condiciones y la Política de privacidad'
                                  />
                              </Copy>} />
                    </Box>
                </FieldGroup>
                <LoaderButton dataTest='register-submit-button'
                  formNoValidate
                  loading={loadingForm}
                  disabled={disabled || !termVersion}
                  model='registration'
                  width='100%'>
                    <FormattedMessage defaultMessage='Continuar' />
                </LoaderButton>
            </Box>
        </Flex>
    </LocalForm>
}

IntentForm.propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    getTerms: PropTypes.func,
    userServiceRegisterIntent: PropTypes.func,
    setEmail: PropTypes.func,
    email: PropTypes.string,
    isUserServiceRegisterEnabled: PropTypes.bool,
}

IntentForm.defaultProps = {
    errors: [],
    promoCode: '',
    disabled: false,
    renderReCaptcha: () => {},
    setEmail: () => {},
    email: null,
    showPromoCode: false,
    getTerms,
    loading: false,
    userServiceRegisterIntent: api.userServiceRegisterIntent,
    isUserServiceRegisterEnabled: false,
}

export default IntentForm
